// Background colors
$main_background: #252525;
$primary_container: #2c2b2b;
$secondary_container: #e8e8e8;
// Text colors
$main_text_color: #e8e8e8;
$text_on_dark: #cfcfcf;
$text_on_light: #252525;
$text_hover_color: #7d7d7d;
// Icons colors
$icon_color: #e8e8e8;
//Gradients
$moneda_gradient: linear-gradient(89.38deg, #8e9eab 10.8%, #eef2f3 49.91%);
$destiny_gradient: linear-gradient(89.44deg, #ffe259 9.73%, #ffa751 41.68%);
$cyberiada_gradient: linear-gradient( 271.04deg, rgba(111, 247, 232, 0.88) 50.23%, rgba(31, 126, 161, 0.88) 89.27%);
$easelle_gradient: linear-gradient( 268.77deg, #feac5e 46.68%, #c779d0 70.85%, #4bc0c8 97.73%);
$gaya_gradient: linear-gradient(266.38deg, #1d976c 67.23%, #93f9b9 97.2%);
$wisdom_gradient: linear-gradient( 180deg, rgba(82, 193, 167, 0.88) 0%, rgba(166, 255, 234, 0.6424) 100%);
//Text properties
%text_properties {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
}

.default-button:hover {
    width: 197px;
    height: 50px;
    background: #fece01;
    border-radius: 25.5px;
    border: 1px solid transparent;
}

.default-button {
    width: 197px;
    height: 50px;
    border-color: #fece01;
    background-color: white;
    border-radius: 25.5px;
}

.header-menu-new {
    font-size: 16px;
    background-color: #000000;
    padding: 14px 10px;
}

.px-5.header-menu-new.align-items-center.row {
    width: 100%;
    margin: 0;
}

@media (min-width: 767px) {
    .header-menu-new .social {
        text-align: center;
        justify-content: space-around !important;
        display: flex !important;
    }
    .wanttoget-text {
        max-width: 50vw !important;
        text-align: left;
    }
    .header-menu-new {
        opacity: 0.95;
        position: sticky;
        top: 0;
        z-index: 1000000000;
    }
}

.header-menu-new>* {
    // font-size: 1em !important;
    text-align: center;
}

.header-menu-new>*:not(.ham-menu) {
    text-align: center;
    justify-content: space-around !important;
}

.header-menu-new a {
    color: $main_text_color;
    text-decoration: none;
    @extend %text_properties;
    font-size: 16px;
}

.header-menu-new a:hover {
    color: $text_hover_color;
}

.img-social {
    cursor: pointer;
    width: 25px;
}

.logo {
    height: 50px;
}

.ham-menu div {
    width: 25px;
    height: 2px;
    background-color: $main_text_color;
    margin: 6px 0;
}

.ham-menu {
    display: none;
    /* display: block !important; */
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .ham-menu {
        display: block !important;
    }
    .close-menu {
        filter: invert(1);
        // font-weight: bold;
    }
}

img.logo-mobile {
    height: 29px;
}

.ham-menu:hover>* {
    filter: invert(0.5);
}

.cardano-title {
    background-image: $cyberiada_gradient;
    background-size: 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    /* color: black; */
    color: white;
    /* color: #ee2c84; */
    font-size: 72px;
    font-weight: bold;
    /* font-size: inherit; */
}

.main-banner-button {
    font-size: 22px;
    color: $text_on_light;
    background-image: $destiny_gradient;
    padding: 10px 80px;
    border-radius: 40px;
    border: 2px solid white;
    margin-top: 60px;
    min-width: 30%;
    text-align: center;
    font-weight: bold;
}

.description-paper {
    max-width: 60%;
    font-weight: bold;
    color: $main_text_color !important;
    font-size: 28px;
    line-height: 1.5em;
    margin-bottom: 20px;
    opacity: 0.88;
}

.proxiesarepart {
    padding: 150px;
    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    background: #252525 url("IMAGES/comb_patter.svg");
    // background: #252525;
    >div {
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        // min-width: 30%;
        max-width: 50%;
        font-size: 18px;
        line-height: 1.5em;
        color: $main_text_color;
    }
    .description-part {
        display: block;
        color: $main_text_color !important;
    }
    .title-proxies-part {
        line-height: 1em;
        overflow: visible;
        max-width: 40% !important;
        font-size: 72px;
        font-weight: bold;
        background-image: $destiny_gradient;
        background-size: 100%;
        -webkit-background-clip: text;
        background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        display: block;
    }
    .main-banner-button {
        font-size: 16px !important;
    }
}

.fly-into {
    // overflow-x: hidden;
    // overflow-y: visible;
    box-shadow: 0px 20px 20px 5px rgba(0, 0, 0, 0.25);
    padding: 100px 0 50px 0;
    background: #2c2b2b;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;
    >div {
        min-width: 30%;
        position: relative;
    }
    .title-proxies-fly {
        // padding-left: 100px;
        text-align: right;
        min-width: 40%;
    }
    img {
        // top: 10em;
        width: 80%;
        filter: drop-shadow(-10px 10px 20px rgba(0, 0, 0, 0.46));
        // transform: translateY(30%);
    }
    .fly-title {
        background-image: $cyberiada_gradient;
        font-size: 72px;
        font-weight: bold;
        background-size: 100%;
        -webkit-background-clip: text;
        background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }
    .fly-description {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        line-height: 1.5em;
        font-weight: bold;
        color: $main_text_color;
        font-size: 28px;
        max-width: 60%;
        margin-top: 100px;
    }
    .description-fly {
        padding: 0 0 0 100px;
        position: relative;
        // top: -10em;
        line-height: normal;
        text-align: left;
    }
}

@media screen and (max-width: 767px) {
    .description-paper {
        opacity: 0.88;
        // max-width: 80%;
        max-width: 80% !important;
        padding: 0;
        margin: auto !important;
        color: $main_text_color;
        font-size: 20px !important;
        line-height: 1.5em;
        text-align: center;
        font-weight: bold;
        // color: // width: 100%;
        margin-bottom: 50px;
    }
    .cardano-title {
        min-width: 100%;
        margin-top: 30px;
        font-size: 38px !important;
        text-align: center;
    }
    .main-banner-button {
        font-size: 16px;
        margin: auto;
        padding: 20px 40px;
        border: none;
        margin-top: 30px;
    }
    .proxiesarepart {
        background: #252525;
        flex-flow: column;
        padding: 0;
        padding-top: 10px;
        >div {
            margin: 20px auto;
            min-width: 90%;
            max-width: 90%;
            font-size: 16px;
        }
        .title-proxies-part {
            font-size: 38px;
        }
    }
    .fly-into {
        background: #252525;
        padding: 0;
        flex-flow: row-reverse;
        // jus;
        >div {
            max-width: none;
        }
        .title-proxies-fly {
            align-self: flex-end;
            transform: translate(40%, 30%);
            max-width: none;
            text-align: center;
        }
        img {
            justify-self: center;
            transform: none;
        }
        .fly-title {
            font-size: 38px;
        }
        .fly-description {
            margin: 0;
            margin-top: 50px;
            max-width: 100%;
            font-size: 20px;
        }
        .description-fly {
            min-width: 60% !important;
            padding: 20px;
        }
    }
}

#nfts {
    padding-top: 200px;
    background: #252525;
    display: flex;
    flex-flow: column;
    .girl {
        position: relative;
        margin-top: 50px;
        display: flex;
        flex-flow: row;
        justify-content: center;
        >div {
            justify-content: center;
            display: flex;
            flex-flow: column;
            position: relative;
            max-width: 40%;
        }
        &-description {
            max-width: 60%;
        }
        &-image {
            max-width: 40% !important;
            align-items: center;
            text-align: center;
            img {
                width: 80% !important;
            }
        }
        &-description-name {
            align-self: self-start;
            font-size: 72px;
            -webkit-background-clip: text;
            background-clip: text;
            -moz-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-text-fill-color: transparent;
            font-weight: bold;
        }
        &-description-header {
            color: #7d7d7d;
            font-weight: bold;
            font-size: 22px;
        }
        &-description-description {
            max-width: 100%;
            font-size: 18px;
            position: relative;
            color: #e8e8e8;
        }
        &::before {
            content: "";
            height: 4px;
            position: absolute !important;
            bottom: 0;
            right: 5%;
            z-index: 5000;
            left: 5%;
        }
    }
    .left-girl {
        .girl-description {
            padding-left: 100px;
        }
        &::before {
            background: linear-gradient(90deg, #ed8b3e 0%, #00000000 100%);
        }
    }
    .right-girl {
        padding-left: 5%;
        .girl-description {
            align-items: flex-start;
        }
        flex-flow: row-reverse;
        &::before {
            background: linear-gradient(-90deg, #ed8b3e 0%, #00000000 100%);
        }
    }
}

@media screen and (max-width: 767px) {
    #nfts {
        padding-top: 100px;
        .girl {
            margin-top: 20px;
            padding: 20px;
            flex-flow: column;
            &-description {
                padding: 0;
                padding-top: 30px !important;
                max-width: 100% !important;
            }
            &-image {
                position: relative;
                &::before {
                    content: "";
                    height: 5px;
                    position: absolute !important;
                    bottom: 0;
                    right: 5%;
                    z-index: 5000;
                    left: 5%;
                    background: linear-gradient(90deg, #ed8b3e 0%, #00000000 100%);
                }
                max-width: 100% !important;
            }
            &-description-name {
                margin: 5px auto;
                font-size: 38px;
            }
            &-description-header {
                margin: auto;
                // margin-top: 30px;
                color: #cfcfcf;
                font-size: 18px !important;
            }
            &-description-description {
                margin: 20px auto;
                font-size: 16px;
                line-height: 1.5em;
            }
        }
        .left-girl {
            .girl-description {
                padding-left: 0;
            }
            &::before {
                display: none;
            }
        }
        .right-girl {
            &::before {
                display: none;
            }
        }
    }
}

#sproxie {
    font-family: "Helvetica", sans-serif;
    padding-right: 15%;
    position: relative;
    height: 46vw;
    width: 100%;
    background: url("IMAGES/proxie_vote.png");
    background-size: contain;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &::before {
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #000000ac;
        position: absolute;
    }
    .sproxie-content-block {
        z-index: 1000;
        display: flex;
        justify-content: space-around;
        flex-flow: column;
    }
    .proxie-title {
        -webkit-background-clip: text;
        background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        font-weight: bold;
        font-size: 72px;
        background-image: $destiny_gradient;
    }
    .proxie-description {
        font-size: 72px;
        line-height: 80px;
        color: #e8e8e8;
        font-weight: bold;
    }
}

@media screen and (max-width: 767px) {
    #sproxie {
        padding: 0;
        text-align: center;
        justify-content: center;
        height: 60vh;
        background-size: auto 100%;
        .proxie-title {
            font-size: 38px;
        }
        .proxie-description {
            font-size: 38px;
            // font-size: 2.5em;
            line-height: 45px;
        }
    }
}

.road {
    scroll-behavior: smooth;
    overflow: hidden;
    padding: 10%;
    background: #2c2b2b;
    color: #e4e4e4;
    &-head {
        color: #e4e4e4;
        margin-bottom: 50px;
        display: flex;
        align-items: top;
        &-title {
            align-items: flex-start;
            display: block;
            line-height: 1em;
            font-size: 72px;
            min-width: 40%;
            background: linear-gradient( 269.74deg, rgba(111, 247, 232, 0.88) 41.25%, rgba(31, 126, 161, 0.88) 73.53%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-fill-color: transparent;
            background-clip: text;
            font-weight: bold;
        }
        &-text {
            font-size: 18px;
        }
    }
    &-map {
        color: #e4e4e4;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }
        -webkit-scroll-behavior-duration: 0.5s;
        -webkit-scroll-behavior-timing-function: ease-in;
        scroll-behavior: smooth;
        overflow: scroll;
        margin-left: -10%;
        margin-right: -10%;
        padding: 10%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        column-gap: 10%;
        &-point {
            min-width: 30%;
            &-image-container {
                min-height: 150px;
            }
            .point-description {
                margin-top: 20px;
                font-size: 18px;
                white-space: pre-line;
            }
            .point-title {
                font-size: 22px;
                font-weight: bold;
            }
        }
    }
    .line-in-image {
        background-image: url("./roadmap/Line 8.svg");
        height: 50px;
        width: 0;
        left: 10px;
        top: 0;
        position: absolute;
        background-repeat: repeat-x;
        &.inactive-line {
            left: 50px;
            background-image: url("./roadmap/Line 9.svg");
        }
    }
    .road-map-point {
        z-index: 1000;
    }
    .road-map-point-image-container {
        position: relative;
    }
    .road-map-point-image {
        z-index: 10000000;
        transform: translateY(-20%);
    }
    .road-controll {
        display: flex;
        justify-content: center;
        &-controll-prev {
            display: flex;
            justify-content: flex-end;
        }
        &-controll-next {
            display: flex;
            justify-content: flex-start;
        }
        .road-button {
            cursor: pointer;
            background: white;
            min-width: 100px;
            min-height: 30px;
            margin: 10px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 50px;
            border: 2px solid white;
            border-color: #fece01;
            background: transparent;
            img svg {
                fill: white !important;
            }
            &.next-button img {
                filter: invert(1);
            }
            &:hover {
                background: #fece01;
                img {
                    filter: invert(1);
                }
            }
            &.next-button:hover img {
                filter: invert(0) !important;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .road {
        overflow: hidden;
        padding: 5%;
        // .road
        padding-top: 50px;
        background: #2c2b2b;
        color: #e4e4e4;
        &-head {
            flex-flow: column;
            &-title {
                font-size: 38px;
                min-width: 100%;
            }
            &-text {
                margin-top: 30px;
                font-size: 16px;
            }
        }
        &-map {
            overflow: scroll;
            margin-left: -10%;
            margin-right: -10%;
            padding: 10%;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            column-gap: 10%;
            &-point {
                min-width: 70%;
                &-image-container {
                    min-height: 150px;
                }
                .point-title {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 20px;
                    min-height: 3em;
                    max-height: 3em;
                }
                .point-description {
                    font-size: 16px;
                }
            }
        }
    }
}

#art-section {
    font-size: 18px;
    padding: 100px;
    background: #2c2b2b;
    color: #e4e4e4;
    display: flex;
    flex-flow: row;
    justify-content: center;
    column-gap: 5%;
    >div {
        >* {
            max-width: 80%;
        }
        min-width: 40%;
        max-width: 40%;
        &.first {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            .main-message {
                font-size: 28px;
                font-weight: bold;
            }
            .trade-section {
                .trade-title {
                    display: block;
                    font-family: "Helvetica";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 45px;
                    background: linear-gradient(269.74deg, rgba(111, 247, 232, 0.88) 41.25%, rgba(31, 126, 161, 0.88) 73.53%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
                a:hover span {
                    color: #fece01;
                }
            }
        }
        &.second {
            .membership-title {
                display: table-cell;
                font-family: "Helvetica";
                font-style: normal;
                font-weight: 700;
                font-size: 72px;
                line-height: 1em;
                margin-bottom: 20px;
                background: linear-gradient( 90deg, #f7971e 0%, #f7991c 2%, #fab210 24%, #fdc307 46.99%, #fece01 71.99%, #ffd200 99.99%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
            .membership-content {
                line-height: 18px;
            }
        }
    }
    .line-delimiter {
        margin: 15px auto 5px auto;
        height: 1px;
        width: 100%;
        background: linear-gradient( 90deg, #e4e4e4 0, rgba(228, 228, 228, 0) 60% 100%);
    }
    a {
        color: #e4e4e4;
        text-decoration: none;
    }
}

.collection-art {
    font-size: 18px;
    background: linear-gradient( 268.77deg, #feac5e 46.68%, #c779d0 70.85%, #4bc0c8 97.73%);
    background: #2c2b2b;
    color: white;
    padding: 50px 100px;
    column-gap: 5%;
    justify-content: center;
    >div {
        min-width: 40%;
        max-width: 40%;
        >* {
            max-width: 80% !important;
        }
    }
    min-width: 100%;
    display: flex;
    &-title {
        font-weight: bold;
        font-size: 72px;
        background: linear-gradient( 268.77deg, #feac5e 46.68%, #c779d0 70.85%, #4bc0c8 97.73%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
}

.collection-art-images-block {
    font-size: 18px;
    padding: 5%;
    background: #2c2b2b;
    .collection-art-images {
        display: flex;
        max-width: 90%;
        margin: auto;
        padding: 15px;
        justify-content: space-between;
        column-gap: 15px;
        background: linear-gradient( 268.77deg, #feac5e 46.68%, #c779d0 70.85%, #4bc0c8 97.73%);
        >* {
            display: flex;
            justify-content: space-around;
            max-width: 19%;
            img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #art-section {
        padding: 20px;
        background: #2c2b2b;
        color: #e4e4e4;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .membership-title,
        .trade-title {
            font-size: 38px !important;
        }
        >div {
            font-size: 16px;
            >* {
                max-width: none;
            }
            margin-top: 20px;
            max-width: none !important;
        }
    }
    .collection-art {
        font-size: 18px;
        background: linear-gradient( 268.77deg, #feac5e 46.68%, #c779d0 70.85%, #4bc0c8 97.73%);
        background: #2c2b2b;
        color: white;
        padding: 20px;
        >div {
            max-width: none;
            >* {
                max-width: none !important;
            }
        }
        min-width: 100%;
        display: flex;
        flex-flow: column;
        &-title {
            font-weight: bold;
            font-size: 38px;
            background: linear-gradient( 268.77deg, #feac5e 46.68%, #c779d0 70.85%, #4bc0c8 97.73%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
    .collection-art-images-block {
        padding: 0;
        background: #2c2b2b;
        .collection-art-images {
            display: flex;
            max-width: 100%;
            margin: auto;
            padding: 5px 10px;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            // column-gap: 15px;
            background: linear-gradient( 268.77deg, #feac5e 46.68%, #c779d0 70.85%, #4bc0c8 97.73%);
            >* {
                margin-top: 2%;
                margin-bottom: 2%;
                display: flex;
                // justify-content: space-between;
                flex-wrap: wrap;
                min-width: 46%;
                img {
                    width: 100%;
                }
            }
        }
    }
    .collection-art-content {
        font-size: 16px;
    }
}

#joinus {
    color: #cfcfcf;
    padding: 130px 50px;
    background: #252525;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    .treasure {
        font-size: 28px;
        font-weight: bold !important;
    }
    .join-comunity-text {
        font-size: 72px;
        font-weight: bold !important;
        background: radial-gradient( 78.93% 78.55% at 65.94% 20.95%, #fdc830 15.1%, #f38735 82.81%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
}

#footer {
    font-size: 16px;
    position: relative;
    background: #252525;
    color: #e8e8e8;
    display: flex;
    justify-content: space-between;
    padding: 5% 10%;
    a {
        color: #a9a9a9;
        &:hover {
            filter: invert(0.3);
        }
    }
    >* {
        // min-width: 20%;
        display: flex;
        flex-flow: column;
        >* {
            margin-top: 5px;
        }
    }
    .f-proxies-title {
        font-size: 18px;
        font-weight: bold;
    }
    .f-social-links {
        display: flex;
        flex-flow: column;
        align-items: center;
        >* {
            margin-top: 10px;
        }
    }
    .cardano-logo {
        max-height: 60px;
        width: auto !important;
        margin-top: 20px;
        align-self: flex-start;
    }
    &::before {
        content: "";
        position: absolute;
        left: 5%;
        right: 5%;
        top: 0;
        height: 1px;
        background: white;
    }
}

.img-social:hover {
    filter: invert(0.4);
}

@media screen and (max-width: 767px) {
    #joinus {
        color: #cfcfcf;
        padding: 100px 50px;
        .treasure {
            font-size: 20px;
        }
        .join-comunity-text {
            font-size: 38px;
            margin-top: 20px;
        }
    }
    #footer {
        position: relative;
        background: #252525;
        color: #e8e8e8;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        padding: 5% 10%;
        font-size: 14px;
        .f-proxie-list {
            font-size: 10px;
        }
        img.cardano-logo {
            height: 44px;
        }
        >* {
            // min-width: 20%;
            display: flex;
            flex-flow: column;
            >* {
                margin-top: 5px;
            }
        }
        .f-proxies-title {
            font-size: 18px;
            font-weight: bold;
        }
        .f-social-links {
            display: flex;
            flex-flow: column;
            align-items: center;
            >* {
                margin-top: 10px;
            }
        }
        .cardano-logo {
            max-height: 60px;
            width: auto !important;
            margin-top: 20px;
            align-self: flex-start;
        }
        &::before {
            content: "";
            position: absolute;
            left: 5%;
            right: 5%;
            top: 0;
            height: 1px;
            background: white;
        }
        .mobile-row-footer {
            min-width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
        }
    }
}

a.disabled-link {
    color: gray;
    cursor: not-allowed;
}

.mint-notification {
    position: absolute;
    font-size: 10px;
    color: white;
    white-space: nowrap;
    text-align: left;
    left: 0;
    // transform: translateX(-50%);
    bottom: calc(100% + 10px);
}

.main-banner-button {
    // cursor: not-allowed;
    position: relative;
}

.main-banner-button:hover .mint-notification {
    -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
    animation: pulsate-fwd 0.5s ease-in-out infinite both;
}

@media screen and (max-width: 767px) {
    .mint-notification {
        top: calc(100% + 20px);
        left: 17px;
    }
}


/* ----------------------------------------------
 * Generated by Animista on 2022-4-24 2:42:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */

@-webkit-keyframes pulsate-fwd {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.06);
        transform: scale(1.06);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulsate-fwd {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.06);
        transform: scale(1.06);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.not-allowed {
    // color: gray;
    cursor: not-allowed !important;
}

@media screen and (max-width:767px) {
    .minting-pop-up {
        bottom: auto !important;
        padding-bottom: 100px;
        box-shadow: 0 0 10000px 10000px #000000db;
    }
}

.minting-pop-up {
    padding-bottom: 100px;
    box-shadow: 0 0 10000px 10000px #000000db;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #252525 !important;
    z-index: 1000000000;
    .back-to-site {
        padding: 30px;
        color: #e8e8e8;
        text-align: right;
        cursor: pointer;
        font-size: 18px;
    }
    .menu-item {
        min-height: 78px;
        padding-left: 40px;
        display: flex;
        align-content: center;
        * {
            display: flex;
            align-items: center;
        }
    }
    .calc-description {}
    .calculator-form {
        font-size: 16px;
        background: #2c2b2b;
        color: #e8e8e8;
        .amount-control {
            margin: 10px 0;
            padding: 10px 0;
            // border-bottom: 1px solid #e8e8e8;
            align-items: center;
            display: flex;
            justify-content: space-between;
            .amount-incremental {
                min-width: 20%;
                display: flex;
                justify-content: space-around;
                font-size: 32px;
                display: flex;
                .amount-plus,
                .amount-minus {
                    cursor: pointer;
                }
                .not-allowed {
                    color: gray;
                    cursor: not-allowed !important;
                }
            }
            .amount-max {
                cursor: pointer;
                background-image: linear-gradient( 89.44deg, #ffe259 9.73%, #ffa751 41.68%);
                z-index: 100000;
                border-radius: 7px;
                position: relative;
                // border: 2px solid blue;
                padding: 1px;
                .amount-border {
                    border-radius: 7px;
                    padding: 10px 25px;
                    background: #2c2b2b;
                    color: #e8e8e8;
                }
                &:hover {
                    background-image: linear-gradient( 199.44deg, #ffe259 9.73%, #ffa751 41.68%);
                }
            }
        }
    }
    .copied-field {
        // background-color: #327245 !important;
        background-image: linear-gradient(266.38deg, #1d976c 67.23%, #93f9b9 97.2%) !important;
        color: black !important;
        transition: 1s all;
    }
    .content-container {
        padding-top: 20px;
        display: flex;
        justify-content: space-around;
        &>* {
            min-width: 30%;
            max-width: 40%;
            padding: 30px;
            border-radius: 20px;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
            background: #2c2b2b;
        }
        div.accordion__panel:not([hidden])>* {
            background-color: transparent;
        }
    }
    @media screen and (max-width: 767px) {
        .mint-adress-in-form {
            flex-flow: column;
        }
        .acordeon-container {
            padding-top: 0px !important;
        }
        .back-to-site {
            padding-top: 0px !important;
            padding-bottom: 5px !important;
        }
        .mint-button-copy {
            text-align: center;
        }
        .mint-string {
            max-width: 100%;
            overflow: AUTO;
            margin-bottom: 10px;
            text-overflow: ellipsis;
            height: 35px;
        }
        .content-container {
            padding-top: 0;
            flex-direction: column;
            justify-content: start;
            &>* {
                min-width: 100%;
                max-width: 100%;
            }
            .acordeon-list {
                margin-left: 10px;
            }
            .calculator-form {
                margin: 0px 30px;
                .amount-incremental {
                    align-items: center;
                    .amount-value {
                        padding: 0 20px;
                    }
                    &>* {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .calc-total {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        font-size: 22px;
        font-weight: bold;
        align-items: center;
        .calc-total-value {
            font-size: 32px;
            display: table-cell;
            font-family: "Helvetica";
            font-style: normal;
            font-weight: 700;
            background: linear-gradient( 90deg, #f7971e 0%, #f7991c 2%, #fab210 24%, #fdc307 46.99%, #fece01 71.99%, #ffd200 99.99%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
    .calc-total {
        border-bottom: 1px solid #E8E8E8;
        padding-bottom: 20px;
    }
    .acordeon-container {
        box-shadow: none;
        color: #e8e8e8;
        background: transparent;
    }
    .accordion {
        font-size: 16px;
        border: none;
    }
    .accordion__button {
        color: #e8e8e8;
        background: transparent;
        position: relative;
    }
    .accordion__button::before {
        transform: rotate(45deg);
        position: absolute;
        right: 0;
    }
    div.accordion__button[aria-expanded="true"]::before {
        transform: rotate(225deg);
    }
    div.accordion__panel:not([hidden]) {
        border-bottom: 1px solid #cfcfcf !important;
        &>* {
            background-color: #2c2b2b;
        }
    }
    .image-acor {
        display: flex;
        img {
            width: 100%;
        }
    }
    .acordeon-container {
        font-size: 18px;
    }
    .compliance-guideline {
        color: #7d7d7d;
    }
    .guidelines {
        color: #e8e8e8;
    }
    .calculator-form {
        min-width: 40%;
    }
    .mint-adress-in-form {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .mint-string {
            transition: 0.5s all;
            font-size: 10px;
            color: #7d7d7d;
            background: #242323;
            border: 1px solid #1b1a1a;
            padding: 3px 3px;
            display: flex;
            align-items: center;
            border-radius: 7px;
        }
        .mint-button-copy {
            cursor: pointer !important;
            transition: 0.5s all;
            padding: 8px 20px;
            background: linear-gradient(89.44deg, #ffe259 9.73%, #ffa751 41.68%);
            border-radius: 7px;
            color: #252525;
            font-weight: bold;
            &:hover {
                background: linear-gradient(-90.44deg, #ffe259 9.73%, #ffa751 41.68%);
            }
            &:active {
                color: gray;
                background: linear-gradient(180.44deg, #ffe259 9.73%, #ffa751 41.68%);
            }
        }
    }
}

.mint-button-copy {
    width: 100%;
    text-align: center;
}

.girl-description-description {
    white-space: pre-line;
}

.image-acor {
    flex-flow: column;
}

.subtitle-liquidity {
    font-weight: bold;
    margin-bottom: 10px;
}

.image-acor {
    font-size: 15px;
}

.image-acor a {
    color: #ffa751;
}

div#accordion__heading-a::before {
    display: none;
}

div#accordion__heading-a:hover {
    background: transparent;
}

a.mint-button-copy {
    width: auto !important;
    margin-left: 20px;
}

.mint-adress-in-form {
    justify-content: center !important;
}

.mint-string {
    position: relative;
}

.mint-string::before {
    position: absolute;
    bottom: 80%;
    content: "copied";
    color: white;
    left: 50%;
    opacity: 0;
    transition: 0.4s all;
    transform: translate(-50%);
}

.copied-field::before {
    opacity: 1;
    bottom: 102%;
}

@media screen and (max-width: 767px) {
    a.mint-button-copy {
        width: auto !important;
        margin-left: 0;
    }
    .copied-field::before {
        opacity: 1;
        bottom: 40%;
        padding: 5px 10px;
        background: black;
        border-radius: 0 0 10px 10px;
    }
}

.minting-pop-up {
    background-image: url('image 4.jpg') !important;
    background-size: cover !important;
}

.minting-pop-up .logo {
    display: none;
}

.back-to-site {
    padding: 0 30px !important;
    padding-top: 0px !important;
    padding-right: 30px !important;
    padding-bottom: 0px !important;
    padding-left: 30px !important;
    color: black !important;
}

@media (min-width:767px) {
    .road-controll {
        display: none !important;
    }
}

.road-head-title {
    width: 100%;
    text-align: center;
}

.footer-logos {
    display: flex;
    justify-content: space-between;
}

.new-feature-graphs {
    color: white;
    text-align: center;
    margin: 20px 10px 0 10px;
}

.membership-title {
    padding-bottom: 20px !important;
}

// .nft-Wisdom .girl-description-description {
//     font-size: 20px !important;
//     font-weight: bold;
// }
.new-content-main-banner {
    color: white;
    font-size: 16px;
    font-weight: lighter;
    background: rgba(128, 128, 128, 0.1);
    padding: 10px;
    border-radius: 10px;
}