.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

#root {
    overflow: hidden;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sold-out {
    color: #EE2C84;
    white-space: nowrap;
    font-weight: bold;
}

#phase-one {
    display: flex;
    justify-content: space-between;
    padding: 8%;
    font-size: 18px;
    flex-flow: column;
}

.phase-first {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.phase-title {
    font-size: 1.5em;
}

@media (min-width:767px) {
    .phase-title {
        margin-top: -50px;
    }
    .phase-progress {
        margin-top: 40px;
    }
    .phase-verify {
        margin-top: 70px;
    }
    .phase-see-soon {
        margin-top: 70px;
    }
    .phase-second {
        max-width: 35vw;
        margin-top: 40px;
    }
    #phase-one {
        flex-flow: row;
    }
}

@media (max-width: 767px) {
    #phase-one {
        border-top: 1px solid rgba(128, 128, 128, 0.41) !important;
    }
    #phase-one>*>* {
        margin-top: 20px;
    }
    #phase-one {
        padding: 20px;
        margin-bottom: 40px;
    }
    .phase-title {
        font-size: 2em;
        font-weight: bold;
        color: #404040 !important;
    }
}

.phase-see-soon {
    font-weight: bold
}

.phase-verify a {
    color: rgb(49, 176, 217);
}

.phase-second img {
    max-width: 100%;
}