#modal {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: fixed;
    top: 5vh;
    z-index: 10;
    left: 5vw;
    right: 5vw;
    bottom: 5vh;
    box-shadow: 0 0 0px 10000px rgba(0, 0, 0, 0.622);
    width: auto;
    /* background: #337ba7fa; */
    background: rgba(255, 255, 255, 1);
    z-index: 100000;
    /* transform: scale(0.0001); */
    opacity: 0;
    transform-origin: center;
    animation: scale_modal 0.5s 1 forwards;
    overflow: none;
    border: 3px solid #094e79fa;
    border-radius: 10px;
    overflow: scroll;
}

@keyframes scale_modal {
    0% {}
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

#close-modal {
    color: black;
    font-weight: bold;
    position: fixed !important;
    right: 20px;
    width: 100px;
    z-index: 10000;
    line-height: 3em;
    top: 20px;
    font-size: 3em;
    /* display: flex; */
    /* flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start; */
}

#close-modal:hover {
    cursor: pointer;
    filter: invert(1);
}

#close-modal:active {
    cursor: pointer;
    filter: invert(0.5);
}

#modal-content {
    padding: 50px !important;
    padding-top: 0px !important;
    height: calc(90% - 4em);
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    /* align-items: flex-end; */
    justify-content: space-between;
}

.mint-notice {
    text-align: center;
    font-size: 18px;
    line-height: 1.8em;
    min-width: 100%;
}

#modal-second-column ul li {
    margin-top: 15px;
    font-size: 15px;
    font-family: "Helvetica", sans-serif;
}

@media screen and (min-width: 767px) {
    #modal-content #modal-second-column {
        border-left: 2px solid black;
        min-height: 40vh;
        margin-bottom: 50px;
        align-items: center;
        display: flex;
        align-self: flex-end;
    }
}

#modal-content #modal-second-column {
    font-family: "Helvetica", sans-serif;
    font-weight: 300;
    text-align: left;
    color: black;
    font-size: 16px;
    max-width: 40%;
}

#modal-content>* {
    font-family: "Helvetica", sans-serif;
    font-weight: 300;
    text-align: left;
    color: black;
    font-size: 16px;
    max-width: 55%;
}

#modal-content #modal-first-column {
    font-family: "Helvetica", sans-serif;
    font-weight: 300;
    text-align: left;
    color: black;
    font-size: 16px;
    min-width: 55%;
    display: flex;
    height: 100%;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

#title-mint {
    text-align: center;
    width: 100%;
    display: flex;
    color: black;
    font-weight: 600;
    font-size: 2.5em;
    justify-content: center;
    column-gap: 30px;
}

#ada-c {
    color: #e1e260;
    font-weight: bold;
}

#address-mint {
    position: relative;
    cursor: pointer;
    font-size: 1em;
    padding: 10px 10px;
    width: 80%;
    /* padding: 20px 20px; */
    font-size: 12px;
    border-radius: 10px;
    background: white;
    color: black;
    transition: 1s all;
    border: 1px solid rgba(211, 211, 211, 0.493);
}

#address-mint:hover::after {
    text-transform: uppercase;
    font-size: 0.7em;
    content: "Click to copy";
    position: absolute;
    bottom: 100%;
    color: white;
    left: 0;
    right: 0;
    text-align: center;
}

#copy-address:hover {
    background: #2270ce;
}

#copy-address:active {
    background: #4d93e8;
}

@keyframes pull_up {
    0% {}
    10% {
        bottom: 100%;
    }
    90% {
        bottom: 100%;
        opacity: 1;
    }
    100% {
        opacity: 0;
        bottom: 60%;
    }
}

.clicked-copy::before {
    text-align: center;
    left: 0;
    right: 0;
    content: "COPIED";
    font-weight: bold;
    font-size: 0.9em;
    color: #13a744c4;
    position: absolute;
    bottom: 60%;
    z-index: -1;
    animation: pull_up 2s 1 linear;
}

#copy-address {
    position: relative;
    cursor: pointer;
    padding: 10px;
    /* padding: 20px; */
    color: white;
    background: #3f97ff;
    border-radius: 8px;
}

#copy-addres-block {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
    width: 100%;
    position: relative;
}

#qr {
    margin-top: 30px;
}

.mobile-notice {
    display: none;
}

.mint-values {
    font-family: "Helvetica", sans-serif;
    font-weight: normal;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: stretch;
}

.mint-value {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 5px 30px;
}

.mint-value-name {
    font-size: 18px;
}

.mint-value-price {
    font-size: 18px;
    font-weight: bold;
}

.main-title {
    max-width: 40%;
    /* min-width: 100%; */
    display: block !important;
    font-size: 2em;
    line-height: 1.5em;
    padding-top: 1em;
    font-weight: bold;
    font-family: "Helvetica", sans-serif;
}

.important-text {
    display: none;
}

@media screen and (max-width: 767px) {
    #title-mint {
        font-size: 1em;
    }
    #modal {
        position: absolute;
        left: 10px;
        top: 10px;
        /* bottom: 10px; */
        bottom: auto;
        /* overflow: scroll; */
        height: auto;
        right: 10px;
    }
    #close-modal {
        right: 5px;
        top: 5px;
        width: 50px !important;
    }
    #modal-content {
        padding: 10px !important;
        max-width: 90%;
        flex-flow: column;
    }
    #modal-content>* {
        width: 100%;
        max-width: none !important;
    }
    #copy-addres-block {
        align-items: center;
        font-size: 16px;
        flex-flow: column;
        max-width: 100%;
    }
    #copy-addres-block>* {
        padding: 15px 10px;
        /* padding: 10px; */
        font-size: 10px;
        max-width: 100%;
        text-align: center;
    }
    #copy-address {
        padding: 15px 30px;
        width: 100%;
        /* max-width: 50%; */
        font-weight: bold;
        color: white !important;
        border-radius: 3px !important;
    }
    #modal-second-column {
        width: 100%;
        font-size: 12px !important;
    }
    @keyframes pull_up_m {
        0% {}
        10% {
            top: 110%;
        }
        90% {
            top: 110%;
            opacity: 1;
        }
        100% {
            opacity: 0;
            top: 60%;
        }
    }
    .clicked-copy::before {
        text-align: center;
        left: 0;
        right: 0;
        content: "COPIED";
        font-weight: bold;
        font-size: 0.9em;
        color: #13a744c4;
        position: absolute;
        top: 60%;
        z-index: -1;
        animation: pull_up_m 2s 1 linear;
    }
    .main-title {
        max-width: 90% !important;
        /* min-width: 100%; */
        display: block !important;
        font-size: 22px !important;
        line-height: 1.5em;
        padding-top: 3em !important;
        font-weight: bold;
        font-family: "Helvetica", sans-serif;
    }
    .mint-notice:not(.mobile-notice) {
        display: none;
    }
    .mint-notice {
        margin-top: 30px;
        font-family: "Helvetica", cursive;
        font-size: 16px;
        line-height: 18px;
        padding: 10px;
        text-align: left;
        /* white-space: nowrap; */
        /* max-width: 100%; */
        /* position: relative; */
    }
    .mobile-notice {
        display: block;
        padding: 0;
    }
    #address-mint {
        width: 100%;
        border-radius: 3px !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .mint-value {
        padding: 5px 0px;
    }
    .mint-value-name,
    .mint-value-price {
        font-size: 15px;
    }
    .important-text {
        display: block;
        font-weight: bold;
        width: 100%;
        text-align: center;
        margin: 40px 0;
    }
    #modal-second-column ul {
        padding-left: 19.5px;
    }
    #modal-second-column ul li {
        margin-top: 3px;
        font-size: 14px;
    }
}