.section-wrapper {
    display: flex;
    font-size: 18px;
    flex-direction: column;
    padding: 100px 150px;
    &.chart-section {
        background: #2c2b2b;
    }
    .section-subtitle {
        font-weight: bold;
        font-size: 28px;
        line-height: 1.5em;
        &.chart-subtitle {
            color: #e8e8e8;
            margin-bottom: 25px;
            line-height: 2em;
            min-height: 100%;
            text-align: center;
        }
    }
    .section-content-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &.title-section-content-wrapper {
            align-items: flex-end;
        }
        .section-content {
            width: 45%;
            max-width: 45%;
            margin: 0 20px;
            .section-list {
                color: #e4e4e4;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 2em;
            }
            .section-chart {
                display: flex;
                flex-direction: column;
                .chart-legend {
                    margin-top: 40px;
                    .chart-legend-item {
                        display: flex;
                        .chart-legend-slice-color {
                            height: 1.25em;
                            width: 1.25em;
                            margin: 5px 10px 0 0;
                        }
                        .chart-legend-title {
                            color: #e4e4e4;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 2em;
                        }
                    }
                }
            }
        }
    }
}

.pieChartLabel {
    font-size: 12px;
}

.hide-desktop {
    display: none;
}

.hide-mobile {
    display: block;
}

@media screen and (max-width: 767px) {
    .hide-mobile {
        display: none;
    }
    .hide-desktop {
        display: block;
    }
    .section-wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px;
        &.chart-section {
            background: #2c2b2b;
        }
        .section-content-wrapper {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            align-items: center;
            &.title-section-content-wrapper {
                align-items: center;
            }
            .section-content {
                width: 100%;
                max-width: 100%;
                margin: 0 20px;
                .section-subtitle {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 1.5em;
                    &.chart-subtitle {
                        color: #e8e8e8;
                        margin-bottom: 25px;
                        line-height: 2em;
                        min-height: 100%;
                    }
                }
                .section-list {
                    color: #e4e4e4;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 2em;
                }
                .section-chart {
                    display: flex;
                    flex-direction: column;
                    .chart-legend {
                        margin-top: 40px;
                        .chart-legend-item {
                            display: flex;
                            .chart-legend-slice-color {
                                height: 1.25em;
                                width: 1.25em;
                                margin: 5px 10px 0 0;
                            }
                            .chart-legend-title {
                                color: #e4e4e4;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 2em;
                            }
                        }
                    }
                }
            }
        }
    }
}

.chart-image {
    max-width: 70%;
    margin: auto;
    display: block;
    margin-top: 50px;
}

@media screen and (max-width: 767px) {
    .chart-image {
        max-width: 80%;
        margin: auto;
        display: block;
        margin-top: 80px;
    }
    .f-col.coloana-4 {
        display: flex;
        width: 100%;
        flex-flow: row;
    }
    .f-col.coloana-4>* {
        display: flex;
        width: 100%;
        flex-flow: row !important;
        justify-content: space-between;
        align-items: center;
    }
}

.new-tab {
    height: 15px;
    position: relative;
    top: 0;
    margin-left: 10px;
}

.trade-title {
    width: auto !important;
    display: flex !important;
    align-items: center;
    border-left: 1px solid transparent;
    padding-left: 3px;
}

.trade-title.trade-link:hover {
    transition: 0.3s all;
    border-left: 1px solid lightblue;
}

.trade-title.trade-link:hover>* {
    filter: invert(0.2);
}

.post-text {
    color: white !important;
    font-size: 17px;
    background: transparent;
    -webkit-text-fill-color: rgba(31, 126, 161, 0.88);
    white-space: nowrap;
    font-weight: 500;
    font-variant: all-small-caps;
    /*     margin-left: 13px; */
    position: absolute;
    bottom: -20%;
}

.trade-title {
    position: relative;
}

.social>* {
    margin-left: 5px;
}

.f-closed {
    max-height: 0px;
    overflow: hidden;
    transition: 0.4s all;
}

.f-opened {
    max-height: 4000px;
    transition: 0.5s all;
}

.togle-action {
    cursor: pointer;
    font-weight: lighter;
    font-size: 14px;
}

.f-proxie-list {
    margin: 10px auto !important;
    transition: 0.4s all;
    font-size: 13px;
}

.f-proxies-title {
    margin-top: 30px !important;
}